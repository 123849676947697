


import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, NavDropdown, Container, Form, Button, Row, Col} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
/*import CONFIG from '../global-vars-trezo.js'*/

const Header = ({ siteTitle }) =>
{

  const data = useStaticQuery(graphql`
  query headersite_fr {
    allStrapiHeaderSite {
      edges {
        node {
          id
          HrefButton1
          HrefButton2
          ShowButton1
          ShowButton2
          TextButton1
          TextButton2
         Logo {
          id
          localFile {
            childImageSharp {
              gatsbyImageData( quality:100, height: 40, width:170, placeholder: NONE)
            }
          }
        }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allStrapiFeaturesHeaderMenus (sort: {order: ASC, fields: Order}){
      edges {
        node {
          Title
          Order
          Href
          id
        }
      }
    }
  }
`)



  return(
      <header>
        {data.allStrapiHeaderSite.edges.map(header => (
            <Navbar key={header.node.id} collapseOnSelect expand="lg" fixed="top" bg="white" className="pt-3" >
              <Container>

                <Navbar.Brand href="/fr/" style={{paddingTop:"0"}}>
                  {header.node.Logo.map( logo=> (
                      <GatsbyImage  style={{verticalAlign:"center"}} key={logo.id} image={logo.localFile.childImageSharp.gatsbyImageData} height="45" className="ml-0 shadow-box-example z-depth-5" alt="Logo Trezorino"/>

                  ))}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                  <Nav className="me-auto header-nav" >
                    <NavDropdown  title="Fonctionnalités" id="basic-nav-dropdown" style={{border:"none"}}>
                          <NavDropdown.Item href="/fr/fonctionnalites/suivi-de-tresorerie/">Suivi de trésorerie</NavDropdown.Item>
                          <NavDropdown.Item href="/fr/fonctionnalites/previsions-de-tresorerie/">Prévisions de trésorerie</NavDropdown.Item>
                          <NavDropdown.Item href="/fr/fonctionnalites/tresorerie-collaborative/">Trésorerie collaborative</NavDropdown.Item>
                          <NavDropdown.Item href="/fr/fonctionnalites/integrations/">Intégrations</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown  title="Cas d'usages" id="basic-nav-dropdown" style={{border:"none"}}>
                      <NavDropdown.Item  href="/fr/cas-usages/batiment-construction/">Batiments / Construction</NavDropdown.Item>
                      <NavDropdown.Item  href="/fr/cas-usages/restaurant/">Restaurants</NavDropdown.Item>
                      <NavDropdown.Item  href="/fr/cas-usages/entreprise-de-services-numeriques/">ESN (SSII)</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link href="/fr/tarifs/">Tarifs</Nav.Link>
                    <Nav.Link href="/fr/blog/">Blog</Nav.Link>

                  </Nav>
                  <Form className="d-flex header-nav-form" xs={12} sm={12} lg={6} xl={6} md={6}>
                    <Row xs={12} sm={12} lg={12} xl={12} md={12}>
                      <Col>
                        <Button  variant="connect" className="header-nav-button" style={{width:"100%", fontSize:"14.4px"}} href={`${process.env.APP_URL}/login`}>Se connecter</Button>
                      </Col>
                      <Col>
                        <Button  variant="demo" className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                      </Col>
                    </Row>


                  </Form>
                </Navbar.Collapse>


              </Container>
            </Navbar>
        ))}

      </header>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
