/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Button} from "react-bootstrap"
import Header from "./header_fr"
import '../css/custom.css'
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo"

const Layout = ({children, seo}) => {

    const data = useStaticQuery(graphql`
     query SiteTitleQuery_fr {
      allStrapiFooterSections (sort: {fields: Order, order: ASC}){
        edges {
          node {
            id
            Title
            Order
            footer_section_lines{
              id
              Href
              Title
              Order
            }
          }
        }
      }
      allStrapiFooters {
        edges {
          node {
            id
            Year
            Logo {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(quality:100, height: 35, placeholder: NONE)
                }
              }
            }
          footer_social_networks {
              id
              Href
              Logo {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality:100, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
     }
   `)

    //   if (typeof window !== "undefined") {
    // // eslint-disable-next-line global-require


    //   require("smooth-scroll")('a[href*="#"]', {

    //   })



    //  }

    return (
        <>
            <Seo seo={seo}/>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />


            <main>{children}</main>



            <hr style={{ opacity:"1", width:"100%", margin:"0px", borderWidth:"0px", height:"1px",backgroundColor:"#FFBE0B"}}></hr>

            <footer className="footer-box">


                {data.allStrapiFooters.edges.map(footer => (
                    <Container key={footer.node.id}>
                        <Row className='mt-0'>

                            <Col className='mt-0 pr-0'>
                                {footer.node.Logo.map( footerlogo=> (
                                    <GatsbyImage key={footerlogo.id} image={footerlogo.localFile.childImageSharp.gatsbyImageData} className="ml-0 shadow-box-example z-depth-5" alt=""/>
                                ))}
                                <ul className="pt-1">
                                    <li className="pl-0" style={{fontSize:"10px"}}> © {new Date().getFullYear()}
                                        {` `}
                                    </li>
                                </ul>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <strong>Suivez-nous</strong>
                                        <Row className="pt-1" >
                                            {footer.node.footer_social_networks.map(social=> (
                                                <Col key={social.id} className="pt-1" xs={2} sm={2} md={3} lg={3} xl={2}>
                                                    <a  href={social.Href} target="_blank" rel="noopener noreferrer">
                                                        {social.Logo.map(sociallogo=> (
                                                            <GatsbyImage  key={sociallogo.id} image={sociallogo.localFile.childImageSharp.gatsbyImageData} alt=""/>
                                                        ))}
                                                    </a>

                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>Fonctionnalités</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/fr/fonctionnalites/suivi-de-tresorerie/">Suivi de trésorerie</a></li>
                                    <li className="pl-0"><a className="footer" href="/fr/fonctionnalites/previsions-de-tresorerie/">Prévisions de trésorerie</a></li>
                                    <li className="pl-0"><a className="footer" href="/fr/fonctionnalites/tresorerie-collaborative/">Trésorerie collaborative</a></li>
                                    <li className="pl-0"><a className="footer" href="/fr/fonctionnalites/integrations/">Intégrations</a></li>
                                </ul>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>Légal</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/fr/mentions-legales/">Mentions légales</a></li>
                                    <li className="pl-0"><a className="footer" href="/fr/politique-de-confidentialite/">Politique de confidentialité</a></li>
                                    <li className="pl-0"><a className="footer" href="/fr/conditions-generales-de-services/">CGS</a></li>
                                </ul>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>About</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/fr/contact/">Contactez-nous</a></li>
                                </ul>
                            </Col>
                            <Col className="pb-3" xs={12} sm={12} md={6} lg={12} xl={12}>
                                <Button variant="outline-primary" className="footer-button"  href="/fr/contact/">Contactez-nous</Button>
                            </Col>
                        </Row>
                    </Container>
                ))}

            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

