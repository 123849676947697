import React from "react"
import Layout from "../../../components/layout_fr"
import Seo from "../../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
/*import CONFIG from '../../global-vars-trezo.js'*/
import { GatsbyImage } from "gatsby-plugin-image";
import trezoPlan from "../../../images/tree.svg"





const Previsions = ({data}) => (
    <Layout>
        <Seo
            titleTemplate={`Trezorino : Logiciel de planification financière, budgétisation et prévisions | Trezorino`}
            title="Projetez votre trésorerie vers l'avenir en temps réel et identifiez les risques et opportunités."
            description="Projetez votre trésorerie vers l'avenir en temps réel et identifiez les risques et opportunités."
            image={trezoPlan}
            lang="fr"
        />

        <div className="">
            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">La gestion des prévisions de trésorerie devient facile.</h1>
                            <p className="section">Avec trezorino, vous pouvez planifier l’avenir financier de votre organisation, effectuer une analyse crédible, prendre les bonnes décisions au bon moment et éviter les mauvaises surprises. </p>
                            {/*<p className="section">En quelques clics seulement vous pouvez définir vos budgets prévisionnels pour chaque catégorie et constater si la situation financière de votre entreprise sera viable à court moyen ou long terme.</p>
                            */}


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/* <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Une Analyse fine de votre prévisionnel de trésorerie</h2>
                            <p className="section-h2">Identifiez instantanément les dépassements de  dépenses et assurez-vous que les objectifs commerciaux sont atteints.</p>
                            <p className="section-h2"> En analysant les écarts entre le réel et le budgétaire, vous pouvez élaborer de nouveaux plans d’actions avec vos équipes et, si nécessaire, ajuster vos prévisions selon le contexte.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-line" >
                {data.trois.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">Le force des scénarios de prévisions</h2>
                            <p className="section-h2">Vous pouvez anticiper chaque éventualité par un plan clair et aborder l’avenir plus sereinement.</p>
                            <p className="section-h2">Créer vos scénarios de trésorerie pour calibrer vos besoins en fonctions des hypothèses choisies. Comparez vos différentes situations et choisissez la stratégie qui garantira votre croissance.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue container-line" >
                {data.cinq.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Synchronisez vos applications métiers</h2>
                            <p className="section-h2">Enrichissez vos prévisions avec vos factures non échues depuis vos applications de facturation ou de comptabilité.</p>
                            <p className="section-h2">Le système de notification de Trezorino vous permet de mieux contrôler vos cycles de paiements fournisseurs et d’anticiper les retards des clients pour préserver votre trésorerie.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container >
                {data.allStrapiTitleMenuFooter.edges.map(title => (
                    <Row key={title.node.id}  className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">{title.node.Title}</h2>
                    </Row>
                ))}

                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4">
                    {data.allStrapiFooterMenus.edges.map(menu => (
                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <GatsbyImage key={ImageCard.id}  image={ImageCard?.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.Title}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/fr"+menu.node.LinkHref+"/"}  className="card-link-footer">En savoir plus<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                                {/* <Button variant="primary" className="collab-button-footer-card"  href="/fr"+menu.node.LinkHref >Essai gratuit</Button> */}
                            </Card.Footer>
                        </Card>
                    ))}
                </Row>

                <Row className="justify-content-md-center pb-5 pt-5">
                </Row>

            </Container>
        </div>
    </Layout>

)

export default Previsions

export const data = graphql`  
query previsions_fr {
  first : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_1"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  second : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_2"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  trois : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_3"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  quatre : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_4"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  cinq : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_4"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  all : allStrapiFeaturesPrevisions(
    filter: {id: {ne: "Features-previsions_1"}}
    sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
  
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
